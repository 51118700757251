

























import Vue from 'vue';
import _ from 'lodash';

import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import notificationMixin from '../../mixin/notification';

const api = new ShopAPI();

class TableCard {
  public id: string|null = null;
  public group: string;
  public active: boolean;
  public no: number|null = null;
  public quantities: number|null = null;
  public table_no: number|null = null;
}

export default Vue.component('index', {
  components: {
    SHPrinterError: () => import('../../components/components/SHPrinterError.vue'),
    SHTableSessionCard: () => import('../../components/components/SHTableSessionCard.vue'),
    SHTableSessionCardSkeleton: () => import('../../components/components/SHTableSessionCardSkeleton.vue'),
    QuickOrder: () => import('../../components/compositions/QuickOrder.vue'),
    NotificationTimeLimit: () => import('../../components/compositions/NotificationTimeLimit.vue'),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [
    notificationMixin,
  ],
  data() {
    return {
      isQuickOrderOpen: false,
      has_data: true,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    Promise.all([
      store.dispatch('fetchTableSessions'),
      store.dispatch('fetchNotificationTimeLimit'),
      store.dispatch("fetchPrinterStatus"),
      store.dispatch("fetchDisplays"),
    ]);
    store.dispatch('setPreviousPage', 'home');
  },
  computed: {
    setting() {
      return store.state.shopSetting;
    },
    isSelfType(): boolean {
      const setting = store.state.shopSetting;
      if (setting === null) {
        return false;
      }

      return setting.has_guide;
    },
    isPrinter(): boolean {
      const setting = store.state.shopSetting;
      if (setting === null) {
        return false;
      }

      return setting.printer_plan;
    },
    isLoading(): boolean {
      return store.state.meta.isLoadingTableSession;
    },
    tables() {
      let has_data = false;
      let tables = [];

      if(store.state.shopSetting.has_guide){
        tables = store.state.tableGroups.map(function (tableGroup) {
          tableGroup.has_table_session = false;

          _.forEach(tableGroup.table_seats, function (table_seat) {
            if (table_seat.table_sessions.length) {
              tableGroup.has_table_session = true;
              has_data = true;
            }
          });

          return tableGroup;
        });
      } else {
        let table_seats = [];
        _.forEach(store.state.tableSessions, function (tableSession) {
          has_data = true;

          table_seats.push({
            name: null,
            table_sessions: [tableSession],
          });
        });

        tables = [{
          has_table_session: has_data,
          name: null,
          table_seats: table_seats
        }];
      }

      this.has_data = has_data;

      return tables;
    },
    details() {
      return store.state.printerStatus;
    },
  },
  methods: {
    handleQuickOrderOpen: function() {
      if (this.isLoading) {
        return;
      }

      this.isQuickOrderOpen = true;
    },
    handleQuickOrderClose() {
      this.isQuickOrderOpen = false;
    },
  },
})
